module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog personnel de labaranlabs","short_name":"Blog personnel de labaranlabs","start_url":"https://www.blog.labaranlabs.com","background_color":"#fff","theme_color":"#3498db","display":"minimal-ui","icon":"src/main.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b20963e5386fbe2d3f9a949aa901ab35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
